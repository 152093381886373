import React from "react"
import PropTypes from "prop-types"
import Button from "@components/ui/buttons/Button"
import styled from "styled-components"
import Heading from "@components/ui/Heading"

export const WorkWithUsStyled = styled.div`
  background-color: var(--color-primary-darkened);
  padding: 56px 0 100px 0;

  .wwu-title {
    font-size: 30px;
  }

  .wwu-cards {
    @media (max-width: 700px) {
      flex-direction: column;
      justify-content: center;
    }
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .wwu-cards-slide {
      @media (max-width: 700px) {
        width: 100%;
        margin-bottom: 10px;
      }
      width: 50%;
      padding: 60px 40px;
      background-color: var(--color-bg);
      min-height: 300px;

      &:nth-of-type(1) {
        @media (max-width: 700px) {
          margin-right: unset;
        }
        margin-right: 20px;
      }

      &:nth-of-type(2) {
        @media (max-width: 700px) {
          margin-left: unset;
        }
        margin-left: 20px;
      }
    }
  }

  .wwu-text {
    @media (max-width: 700px) {
      font-size: 22px;
      text-align: center;
    }
    color: var(--color-text-light);
    margin: 35px 0;
    font-size: 30px;
    text-align: center;
    font-weight: 500;
  }

  .cta-button {
    color: var(--color-text-light);
    background-color: var(--color-secondary);
    cursor: pointer;

    &:hover {
      background-color: var(--color-secondary-darkened);
    }
  }
`
export const WorkWithUs = ({ title, text, cards, buttons, link }) => {
  cards = cards?.filter(card => card.contentArea)
  return (
    <WorkWithUsStyled className="conclusion wwu">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            {title && (
              <Heading node="h2" color="white">
                {title}
              </Heading>
            )}
            <div className="content">
              {cards && (
                <div className="wwu-cards">
                  {cards.map((card, key) => {
                    return (
                      <div
                        key={key}
                        className="wwu-cards-slide"
                        dangerouslySetInnerHTML={{ __html: card.contentArea }}
                      />
                    )
                  })}
                </div>
              )}
              <div
                className="wwu-text"
                dangerouslySetInnerHTML={{ __html: text }}
              />
            </div>
            {buttons &&
              buttons.map((button, key) => (
                <Button key={key} color="orange" to={button.buttonLink.uri}>
                  {button.buttonText}
                </Button>
              ))}
          </div>
        </div>
      </div>
    </WorkWithUsStyled>
  )
}

WorkWithUs.propTypes = {
  title: PropTypes.string,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      contentArea: PropTypes.string,
    })
  ),
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      buttonText: PropTypes.string,
      buttonLink: PropTypes.shape({
        uri: PropTypes.string,
      }),
    })
  ),
}

WorkWithUs.propTypes = {
  title: `Work With Us`,
}
