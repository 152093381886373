import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import SwiperCore, { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper.scss"

import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"
import {
  CircularButtonNext,
  CircularButtonPrev,
} from "@components/ui/slider/arrows"
import Heading from "@components/ui/Heading"

SwiperCore.use([Pagination, Navigation])

const StrategyContainerStyled = styled.section`
  margin-top: 79px;

  .strategy-copy {
    margin-bottom: 34px;

    @media (min-width: 500px) {
      margin-bottom: 79px;
    }

    .title {
      text-align: center;
      color: var(--color-primary);
      font-size: 25px;
      line-height: 24px;
      font-weight: 700;
      margin-bottom: 20px;
    }

    .content {
      color: #222;
      font-size: 23px;
      line-height: 35px;
      text-align: center;
      font-weight: 300;
    }

    .questions-copy {
      color: #949494;
      font-size: 18px;
      line-height: 34px;
      text-align: center;
      font-weight: 300;
      margin-top: 34px;
    }
  }
`

const StrategySliderStyled = styled.div`
  overflow: hidden;

  .swiper-slide {
    width: 325px;
    padding-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    ${breakpointsMedia(breakpoints.phablet)} {
      width: 563px;
    }
  }

  .slick-dots {
    text-align: center;
    list-style: none;
    margin: 0;

    li {
      display: inline-block;
    }

    .slick-active button:before {
      opacity: 1;
    }

    li button {
      font-size: 0;
      line-height: 0;
      display: block;
      height: 24px;
      width: 32px;
      padding: 5px;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: none;
      background: transparent;

      &:before {
        height: 9px;
        width: 9px;
        background-color: var(--color-primary);
        content: "";
        opacity: 0.2;
        border-radius: 100%;
        display: inline-block;
        line-height: 1;
      }
    }
  }
`

const QuestionStyled = styled.section`
  padding: 32px 18px;

  -webkit-box-shadow: 0 25px 50px 0 rgba(0, 0, 0, 0.07);
  box-shadow: 0 25px 50px 0 rgba(0, 0, 0, 0.07);
  background-color: var(--color-bg);
  margin: 22px 25px 0;
  width: 325px;

  @media (min-width: 500px) {
    padding: 30px;
    width: 563px;
  }

  .question-title {
    color: #525356;
    font-size: 26px;
    line-height: 37px;
    text-align: center;
    margin-bottom: 37px;
    font-weight: 700;
  }

  .question-answer {
    margin: 0 auto;
    opacity: 0.5;
    color: #222222;
    font-size: 16px;
    line-height: 26px;
    text-align: left;

    @media (min-width: 500px) {
      font-size: 19px;
      line-height: 31px;
    }

    p {
      margin: 0 0 20px;
    }
  }
`

const StrategySlider = ({ questions }) => {
  return (
    <StrategySliderStyled className="strategy-questions">
      <Swiper
        style={{
          position: `relative`,
        }}
        slidesPerView="auto"
        loop="true"
        centeredSlides="true"
        navigation={{
          nextEl: ".button-next",
          prevEl: ".button-prev",
        }}
        breakpoints={{
          100: {
            slidesPerView: 1,
          },
          600: {
            slidesPerView: "auto",
          },
        }}
      >
        {questions.map(({ question, questionAnswer }, key) => {
          return (
            <SwiperSlide key={key}>
              <QuestionStyled className="question">
                <Heading node="h4" className="question-title">
                  <span dangerouslySetInnerHTML={{ __html: question }} />
                </Heading>
                <div
                  className="question-answer"
                  dangerouslySetInnerHTML={{ __html: questionAnswer }}
                />
              </QuestionStyled>
            </SwiperSlide>
          )
        })}

        <CircularButtonPrev className="button-prev">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="angle-left"
            className="svg-inline--fa fa-angle-left fa-w-8"
            role="img"
            viewBox="0 0 256 512"
          >
            <path
              fill="currentColor"
              d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"
            />
          </svg>
        </CircularButtonPrev>
        <CircularButtonNext className="button-next">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="angle-right"
            className="svg-inline--fa fa-angle-right fa-w-8"
            role="img"
            viewBox="0 0 256 512"
          >
            <path
              fill="currentColor"
              d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"
            />
          </svg>
        </CircularButtonNext>
      </Swiper>
    </StrategySliderStyled>
  )
}

export const StrategyContainer = ({
  title,
  content,
  questions,
  questionsCopy,
}) => (
  <StrategyContainerStyled>
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="strategy-copy">
            {title && (
              <div style={{ textAlign: "center" }}>
                <Heading node="h2" underlined>
                  {title}
                </Heading>
              </div>
            )}
            {content && (
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            )}
            {questionsCopy && (
              <div
                className="questions-copy"
                dangerouslySetInnerHTML={{ __html: questionsCopy }}
              />
            )}
          </div>
        </div>
      </div>
    </div>

    {questions && <StrategySlider questions={questions} />}
  </StrategyContainerStyled>
)

StrategyContainer.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  questions: PropTypes.array,
  questionsCopy: PropTypes.string,
}
