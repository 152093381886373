import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"

import Seo from "@components/Seo"
import WhatIsHero from "@components/sections/heros/WhatIsHero"
import FullIntroduction from "@components/sections/FullIntroduction"
import Conclusion from "@components/sections/Consultion"
import { MarketingRoles } from "@components/sections/MarketingRoles"
import { StrategyContainer } from "@components/sections/StrategyContainer"
import { BlogPosts } from "@components/sections/BlogPosts"
import { WorkWithUs } from "@components/sections/WorkWithUs"
import InitiativeSection from "@components/sections/InitiativeSection"

export default function WhatIsPage({ data }) {
  const page = data.allWpPage.nodes[0]

  return (
    <>
      <Seo {...page.seo} />
      <Helmet bodyAttributes={{ class: "full-hero" }} />
      <WhatIsHero {...page.acfWhatIs?.whatIsHeroContent} />
      <div id="what_is" className="tmpl-what-is" style={{ marginTop: "60px" }}>
        {page.acfWhatIs?.introduction?.content && (
          <FullIntroduction {...page.acfWhatIs.introduction} />
        )}
        {page.acfWhatIs?.marketingRoles && (
          <MarketingRoles roles={page.acfWhatIs.marketingRoles} />
        )}
        {page.acfWhatIs?.questions && (
          <StrategyContainer
            questions={page.acfWhatIs.questions}
            questionsCopy={page.acfWhatIs.questionsCopy}
            title={page.acfWhatIs.strategyTitle}
            content={page.acfWhatIs.strategyContent}
          />
        )}
        {page.acfWhatIs?.initiativeSlides && (
          <InitiativeSection
            title={page.acfWhatIs.initiativeTitle}
            content={page.acfWhatIs.initiativeContent}
            slides={page.acfWhatIs.initiativeSlides}
          />
        )}
        {page.acfWhatIs?.relatedArticles?.articles && (
          <BlogPosts
            title={page.acfWhatIs.relatedArticles.title}
            posts={page.acfWhatIs.relatedArticles.articles}
          />
        )}
        {page.acfWhatIs?.conclusion && (
          <Conclusion content={page.acfWhatIs.conclusion} />
        )}

        {page.acfWhatIs?.workWithUs?.title && (
          <WorkWithUs {...page.acfWhatIs.workWithUs} />
        )}
      </div>
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    allWpPage(
      filter: {
        template: { templateName: { eq: "What Is Template" } }
        slug: { eq: $slug }
      }
    ) {
      nodes {
        uri
        title
        content
        link
        seo {
          canonical
          cornerstone
          focuskw
          metaDesc
          metaKeywords
          metaRobotsNofollow
          metaRobotsNoindex
          opengraphAuthor
          opengraphDescription
          opengraphModifiedTime
          opengraphPublishedTime
          opengraphPublisher
          opengraphSiteName
          opengraphTitle
          opengraphType
          opengraphUrl
          opengraphImage {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: FIXED)
              }
            }
          }
          title
          twitterDescription
          twitterTitle
          schema {
            raw
          }
        }
        title
        acfWhatIs {
          introduction: whatisintroduction {
            title
            content
          }
          conclusion
          whatIsHeroContent {
            preamble
            title
            backgroundImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 80
                    placeholder: BLURRED
                    transformOptions: { cropFocus: CENTER }
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
          initiativeTitle
          initiativeContent
          initiativeSlides {
            text
            icon {
              localFile {
                publicURL
                extension
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
          workWithUs {
            title
            text
            cards {
              contentArea
            }
            buttons: button {
              buttonText
              buttonLink {
                ... on WpPost {
                  uri
                }
                ... on WpPage {
                  uri
                }
              }
            }
          }
          strategyTitle
          strategyContent
          questionsCopy
          relatedArticles {
            title
            articles {
              ... on WpPost {
                id
                uri
                title
                featuredImage {
                  node {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          width: 584
                          height: 400
                          transformOptions: { fit: COVER, cropFocus: CENTER }
                          layout: CONSTRAINED
                        )
                      }
                    }
                  }
                }
              }
            }
          }
          questions {
            questionAnswer
            question
          }
          marketingRoles {
            callOut
            callOutColor
            callOutName
            content
            eg
            factsFiguresNum
            imagePosition
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 586
                    quality: 80
                    transformOptions: { cropFocus: CENTER }
                    layout: CONSTRAINED
                  )
                }
              }
            }
            icon {
              localFile {
                publicURL
              }
            }
            theFactOrFigure
            roleShortName
            roleShortNameColor
            roleLongName
            roleLink
            preTitleColor
            preTitle
            preContent
            source {
              title
              target
              url
            }
          }
        }
      }
    }
  }
`
