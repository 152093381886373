import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"
import Heading from "@components/ui/Heading"

const FullIntroductionStyled = styled.section`
  padding: 20px 0 100px 0;

  .title {
    font-size: 30px;
    font-weight: 700;
    margin: 0 0 26px;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      font-size: 36px;
    }
  }

  .content {
    font-weight: 300;
    color: #222222;
    font-size: 18px;
    line-height: 34px;
  }
`

const FullIntroduction = ({ title, content }) => {
  return (
    <FullIntroductionStyled>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="intro">
              {title && <Heading>{title}</Heading>}
              {content && (
                <div
                  className="content"
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </FullIntroductionStyled>
  )
}

FullIntroduction.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
}

export default FullIntroduction
