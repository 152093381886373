import React from "react"
import styled from "styled-components"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper.scss"
import {
  CircularButtonNext,
  CircularButtonPrev,
} from "@components/ui/slider/arrows"
import CustomGatsbyImage from "@components/utils/CustomGatsbyImage"
import Heading from "@components/ui/Heading"

const InitiativeSliderStyled = styled.div`
  margin-top: 25px;

  .swiper-slide {
    opacity: 0.5;
  }

  .swiper-slide-active {
    opacity: 1;
  }

  .slick-track {
    padding-bottom: 46px;
  }

  .slick-slide {
    outline: none;
  }

  .slick-dots {
    text-align: center;
    list-style: none;
    margin: 0;

    li {
      display: inline-block;
    }

    .slick-active button:before {
      opacity: 1;
    }

    li button {
      font-size: 0;
      line-height: 0;
      display: block;
      height: 24px;
      width: 32px;
      padding: 5px;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: none;
      background: transparent;

      &:before {
        height: 9px;
        width: 9px;
        background-color: var(--color-bg);
        content: "";
        opacity: 0.2;
        border-radius: 100%;
        display: inline-block;
        line-height: 1;
      }
    }
  }

  .swiper-slide {
    width: 300px;
  }

  .initiative-slide {
    background-color: var(--color-bg);
    padding: 35px;
    height: 300px;

    margin: 40px 25px;
    box-shadow: 0 25px 50px 0 rgba(0, 0, 0, 0.07);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img.initiative-slide-icon {
      width: 135px;
      text-align: center;
      margin: 0 auto;
    }

    .question-answer {
      margin-top: 20px;
      font-weight: 700;
      font-size: 25px;
      line-height: 30px;
      text-align: center;
    }
  }
`

const InitiativeContainerStyled = styled.section`
  margin-top: 79px;

  .con {
    .title {
      text-align: center;
    }

    .content {
      color: #222;
      font-size: 23px;
      line-height: 35px;
      text-align: center;
      font-weight: 300;
    }
  }
`

const InitiativeSection = ({ title, content, slides }) => {
  return (
    <InitiativeContainerStyled>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="con">
              <Heading node="h2" underlined>
                {title}
              </Heading>
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>
          </div>
        </div>
      </div>

      {slides && (
        <InitiativeSliderStyled>
          <Swiper
            style={{
              position: `relative`,
            }}
            slidesPerView="auto"
            loop="true"
            centeredSlides="true"
            navigation={{
              nextEl: ".button-next",
              prevEl: ".button-prev",
            }}
            breakpoints={{
              100: {
                slidesPerView: 1,
              },
              600: {
                slidesPerView: "auto",
              },
            }}
          >
            {slides.map(slide => {
              return (
                <SwiperSlide>
                  <div className="initiative-slide">
                    <CustomGatsbyImage {...slide.icon.localFile} />
                    <div
                      className="question-answer"
                      dangerouslySetInnerHTML={{ __html: slide.text }}
                    />
                  </div>
                </SwiperSlide>
              )
            })}

            <CircularButtonPrev className="button-prev">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="angle-left"
                className="svg-inline--fa fa-angle-left fa-w-8"
                role="img"
                viewBox="0 0 256 512"
              >
                <path
                  fill="currentColor"
                  d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"
                />
              </svg>
            </CircularButtonPrev>
            <CircularButtonNext className="button-next">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="angle-right"
                className="svg-inline--fa fa-angle-right fa-w-8"
                role="img"
                viewBox="0 0 256 512"
              >
                <path
                  fill="currentColor"
                  d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"
                />
              </svg>
            </CircularButtonNext>
          </Swiper>
        </InitiativeSliderStyled>
      )}
    </InitiativeContainerStyled>
  )
}

InitiativeSection.propTypes = {}

export default InitiativeSection
