import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { motion } from "framer-motion"
import styled from "styled-components"
import * as PropTypes from "prop-types"
import classNames from "classnames"
import Heading from "@components/ui/Heading"

export const BlogArticleStyled = styled.article`
  display: flex;
  flex-direction: column;

  a {
    text-decoration: none;
  }

  .blogItem {
    &:after {
      content: none;
    }
  }

  .blog-posts-output-title {
    margin-top: 20px;
    text-decoration: none;

    a {
      text-decoration: none;
    }
  }

  --offset: 0;
  --top: var(--offset);
  --bottom: var(--offset);
  --right: var(--offset);
  --left: var(--offset);

  .blog-posts-output-link {
    position: relative;
    overflow: hidden;
    background-color: var(--color-primary);

    .placeholder {
      padding-top: 68.5%;
      position: relative;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .placeholder-wrap ~ .triangle-bl {
      border-color: transparent transparent var(--color-secondary) transparent;
    }

    .blogItem {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center;
      transition: all 0.7s ease;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
      }

      &:hover {
        transform: scale(1.3);
      }

      .outputItemInner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 2;

        .outputTitle {
          color: var(--color-text-light);
          font-size: 20px;
          line-height: 30px;
          text-align: center;
          font-weight: 700;
          margin-top: 0;
          padding: 0;
          text-shadow: unset;
        }
      }
    }

    .triangle-tr,
    .triangle-bl {
      transition: all 0.5s;
    }

    .triangle-tr {
      border-width: 0 90px 90px 0;
      border-color: transparent var(--color-secondary) transparent transparent;
      right: var(--right);
      top: var(--top);
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
    }

    .triangle-bl {
      border-width: 0 90px 90px 0;
      border-color: transparent transparent var(--color-primary) transparent;
      left: var(--left);
      bottom: var(--bottom);
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
    }
  }

  &:hover {
    --offset: -20px;

    text-decoration: none;

    .job-icon {
      filter: grayscale(0);
    }
  }
`

const BasicBlogArticle = ({ index, title, uri, featuredImage }) => {
  const image =
    featuredImage?.node?.localFile && getImage(featuredImage.node.localFile)

  return (
    <>
      <BlogArticleStyled
        className=""
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay={100 * index}
      >
        <Link to={uri} className="blog-posts-output-link">
          <motion.div
            whileHover={{
              scale: 1.2,
              transition: { duration: 0.5 },
            }}
            className={classNames({ "placeholder-wrap": !image })}
          >
            {image ? (
              <GatsbyImage image={image} alt={""} />
            ) : (
              <div className="placeholder">
                <img
                  src="/svgs/logo-fruit-white.svg"
                  alt={"Passion Digital Logo"}
                  width="100px"
                />
              </div>
            )}
          </motion.div>
          <div className="triangle-tr" />
          <div className="triangle-bl" />
        </Link>
        <Link to={uri} className="blog-posts-output-title">
          <Heading node="h3">{title}</Heading>
        </Link>
      </BlogArticleStyled>
    </>
  )
}

BasicBlogArticle.propTypes = {
  index: PropTypes.number,
  title: PropTypes.string,
  uri: PropTypes.string,
  featuredImage: PropTypes.object,
}

export default BasicBlogArticle
