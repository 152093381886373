import React from "react"
import PropTypes from "prop-types"

import BackgroundImage from "gatsby-background-image"
import { HeroStyle } from "@styles/organisms/Hero"
import styled from "styled-components"
import { scrollTo } from "@components/utils/helpers"
import headerVars from "@styles/variables/HeaderVars"
import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"
import { convertToBgImage } from "gbimage-bridge"
import Heading from "@components/ui/Heading"

const WhatIsHeroStyle = styled(HeroStyle)`
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 100%;
  min-height: 440px;
  position: relative;
  padding-top: ${headerVars.header_height_mobile};

  ${breakpointsMedia(breakpoints.tabletWide)} {
    max-height: 749px;
    min-height: 620px;
    height: 100vh;
    position: relative;
    z-index: 10;
    padding-top: 0;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .hero-image {
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute !important;
  }

  .hero-content {
    position: relative;
    text-align: center;

    @media (max-width: 992px) {
      padding: 40px 0;
    }

    .title {
      font-size: 36px;
      color: var(--color-text-light);
      font-weight: 700;

      @media (max-width: 992px) {
        margin-top: 25px;
      }

      ${breakpointsMedia(breakpoints.tabletWide)} {
        font-size: 48px;
      }
    }

    .preamble {
      color: var(--color-text-light);
      font-weight: 500;
      margin: 10px 0;
      padding: 0;
      font-size: 17px;
      line-height: 27px;
      text-shadow: 0 4px 0 rgba(0, 0, 0, 0.5);

      ${breakpointsMedia(breakpoints.tabletWide)} {
        font-size: 20px;
      }
    }
  }

  .st {
    width: auto;
    text-align: center;

    color: var(--color-text-light);
    font-weight: 500;
    cursor: pointer;
    display: table;
    margin: auto;
    margin-top: 24px;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      margin-top: 0;
      position: absolute;
      bottom: -133px;
      -webkit-transform: translate(-50%, 0);
      -ms-transform: translate(-50%, 0);
      transform: translate(-50%, 0);
      left: 50%;
    }

    .title {
      color: var(--color-text-light);
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
    }

    a {
      color: var(--color-text-light);
      text-decoration: none;
    }

    i,
    svg {
      color: var(--color-text-light);
      display: block;
      margin-top: 7px;
      margin: auto;

      ${breakpointsMedia(breakpoints.tabletWide)} {
        margin-top: 20px;
      }
    }
  }
`

const WhatIsHero = ({ title, preamble, backgroundImage }) => {
  const scrollToContent = () => scrollTo(document.querySelector("#what_is"))
  const bgImage = convertToBgImage(
    backgroundImage?.localFile?.childImageSharp?.gatsbyImageData
  )
  return (
    <WhatIsHeroStyle>
      {backgroundImage && (
        <BackgroundImage className="hero-image" {...bgImage} />
      )}
      <div className="overlay" />
      <div className="hero-content">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-10 col-md-push-1">
              <Heading color="white">{title}</Heading>
              <div
                className="preamble"
                dangerouslySetInnerHTML={{ __html: preamble }}
              />
            </div>
          </div>
        </div>
        <div className="st">
          <div
            className="title"
            data-scroll-to="#what_is"
            onKeyDown={scrollToContent}
            onClick={scrollToContent}
            tabIndex={0}
            role="button"
          >
            {title}...
            <div>
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="angle-down"
                className="svg-inline--fa fa-angle-down fa-w-10"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
              >
                <path
                  fill="currentColor"
                  d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"
                ></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </WhatIsHeroStyle>
  )
}

WhatIsHero.propTypes = {
  title: PropTypes.string,
  preamble: PropTypes.string,
  backgroundImage: PropTypes.object,
}

export default WhatIsHero
