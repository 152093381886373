import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { MarketingRole } from "./MarketingRole"
import { scrollTo } from "@components/utils/helpers"
import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"
import Heading from "@components/ui/Heading"
import Spacer from "@components/ui/Spacer"

const ScrollNavStyled = styled.section`
  .what-is-title {
    text-align: center;
    margin-bottom: 51px;
  }

  .marketing-roles {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0 0 92px;

    li {
      color: var(--color-txt);
      cursor: pointer;
      text-align: center;
      width: 50%;
      min-width: 155px;
      margin-bottom: 20px;

      ${breakpointsMedia(breakpoints.tabletWide)} {
        width: 25%;
      }

      a {
        font-weight: 700;
        font-size: 16px;
        text-decoration: none;
      }
    }

    .icon {
      margin: auto auto 30px;
      width: 61px;
      height: 73px;
      display: block;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
`

const ScrollNav = ({ title, roles }) => {
  const filteredRoles = roles.filter(role => role.roleLongName && role.icon)

  if (!filteredRoles.length) return <></>

  return (
    <ScrollNavStyled className="scroll-to-titles marketing-roles-con">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            {title && (
              <>
                <div className="text-center">
                  <Heading node="h3">{title}</Heading>
                </div>
                <Spacer y="sm" />
              </>
            )}
            {filteredRoles && (
              <ul className="marketing-roles">
                {filteredRoles.map((role, key) => {
                  const scrollToRole = () => {
                    scrollTo(document.querySelector(`#role_${key}`), true)
                  }

                  return (
                    <li key={key}>
                      <div
                        onClick={scrollToRole}
                        onKeyPress={scrollToRole}
                        tabIndex={0}
                        role="button"
                      >
                        {role.icon && (
                          <i
                            className="icon"
                            style={{
                              backgroundImage: `url(${role.icon.localFile.publicURL})`,
                            }}
                          />
                        )}
                        {role.roleLongName}
                      </div>
                    </li>
                  )
                })}
              </ul>
            )}
          </div>
        </div>
      </div>
    </ScrollNavStyled>
  )
}

const MarketingRolesSectionsStyled = styled.section`
  border-bottom: 1px solid #e5e9ea;

  .pre-title {
    @media only screen and (max-width: 700px) {
      overflow-wrap: break-word;
      word-wrap: break-word;
    }
    @media (max-width: 992px) {
      font-size: 30px;
    }
    font-size: 36px;
    font-weight: 700;
    margin: 0 0 26px;
  }

  .pre-title-orange {
    color: var(--color-secondary);
  }

  .pre-title-purple {
    color: var(--color-primary);
  }

  .pre-content {
    @media (max-width: 700px) {
      padding-bottom: 20px;
    }
    line-height: 32px;
    font-size: 18px;
    padding-bottom: 40px;
  }

  .title.role-short-name-color-orange {
    color: var(--color-secondary);
  }

  .callout-bg-color-orange {
    background: var(--color-secondary);
  }

  .title.role-short-name-color-purple {
    color: var(--color-primary);
  }

  .callout-bg-color-purple {
    background: var(--color-primary);
  }

  .marketing-roles-section:nth-child(odd) {
    .call-out {
      ${breakpointsMedia(breakpoints.tabletWide)} {
        -webkit-box-shadow: 0 25px 50px 0 rgba(0, 0, 0, 0.17);
        box-shadow: 0 25px 50px 0 rgba(0, 0, 0, 0.17);
      }

      color: var(--color-text-light);
    }
  }

  .marketing-roles-section:nth-child(even) {
    .call-out {
      ${breakpointsMedia(breakpoints.tabletWide)} {
        -webkit-box-shadow: 0 25px 50px 0 rgba(0, 0, 0, 0.17);
        box-shadow: 0 25px 50px 0 rgba(0, 0, 0, 0.17);
      }

      color: var(--color-text-light);
    }
  }
`

export const MarketingRoles = props => {
  return (
    <>
      <ScrollNav {...props} />
      {props.roles && (
        <section className="marketing-roles-sections-con">
          <div className="container">
            <div className="row">
              <MarketingRolesSectionsStyled className="col-md-12">
                {props.roles.map((role, key) => (
                  <MarketingRole key={key} index={key} {...role} />
                ))}
              </MarketingRolesSectionsStyled>
            </div>
          </div>
        </section>
      )}
    </>
  )
}

MarketingRoles.propTypes = {
  title: PropTypes.string,
  roles: PropTypes.array.isRequired,
}

MarketingRoles.defaultProps = {
  title: `Types of Digital Marketing Services and their Roles`,
}
