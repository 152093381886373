import React from "react"
import classNames from "classnames"
import { Link } from "gatsby"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"
import * as PropTypes from "prop-types"
import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"
import { convertToBgImage } from "gbimage-bridge"
import Heading from "@components/ui/Heading"

const MarketingRoleStyled = styled.section`
  .left-col {
    a {
      text-decoration: none;
    }
  }

  .title {
    @media only screen and (max-width: 700px) {
      overflow-wrap: break-word;
      word-wrap: break-word;
    }
    font-size: 24px;
    font-weight: 700;
    margin-bottom: -20px;

    ${breakpointsMedia(breakpoints.tablet)} {
      margin-bottom: -20px;
      font-size: 28px;
    }

    ${breakpointsMedia(breakpoints.tabletWide, "max")} {
      padding-left: 15px;
    }

    &.sm-text {
      font-size: 24px;
      @media (min-width: 768px) {
        font-size: 28px;
      }
    }
  }

  .content-wrap {
    background-color: var(--color-bg);
    padding-top: 40px;
    padding-bottom: 20px;
    min-height: 579px;
    margin-bottom: 28px;

    @media (max-width: 992px) {
      margin-bottom: 0;
      padding: 20px 5% 30px;
    }
  }

  .content {
    margin: 10px 0;
    padding: 0;
    font-size: 17px;
    line-height: 27px;
    color: var(--color-text);
    text-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  }

  .eg {
    opacity: 0.5;
    color: var(--color-text);
    font-size: 14px;
    line-height: 22px;
    margin-top: 20px;
  }

  .image-wrap {
    width: 100%;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      height: 620px;
      position: absolute;
      width: 115%;
      -webkit-box-shadow: -2px 6px 50px 5px rgba(0, 0, 0, 0.17);
      box-shadow: -2px 6px 50px 5px rgba(0, 0, 0, 0.17);
    }

    .image {
      ${breakpointsMedia(breakpoints.tabletWide)} {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }

      img {
        ${breakpointsMedia(breakpoints.tabletWide)} {
          display: none;
        }
      }
    }
  }

  .call-out {
    padding: 30px 35px;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;

    @media (min-width: 600px) {
      padding: 60px 56px;
    }

    ${breakpointsMedia(breakpoints.tabletWide)} {
      -webkit-box-shadow: 0 25px 50px 0 rgba(0, 0, 0, 0.17);
      box-shadow: 0 25px 50px 0 rgba(0, 0, 0, 0.17);
      position: absolute;
      bottom: -100px;
      width: 334px;
    }

    .inner {
      -webkit-transform: translateZ(39px);
      transform: translateZ(39px);
      color: var(--color-text-light);
    }

    color: var(--color-text-light);
    z-index: 11;
    font-size: 25px;
    font-style: italic;
    line-height: 37px;
    font-weight: 300;

    .call-out-name {
      margin-top: 4px;
      font-style: normal;
      color: var(--color-text-light);
      font-size: 14px;
      line-height: 30px;
      font-weight: 500;
    }
  }

  .role-wrapper {
    clear: both;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 50px;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      margin-bottom: 101px;
    }
  }

  .role-wrapper {
    .left-col {
      width: 100%;
      position: relative;
      z-index: 10;

      ${breakpointsMedia(breakpoints.tabletWide)} {
        float: left;
        width: 56%;
        margin-top: 15px;
      }
    }

    .right-col {
      position: relative;
      width: 100%;

      ${breakpointsMedia(breakpoints.tabletWide)} {
        float: left;
        width: 44%;
        height: 620px;
      }
    }
  }

  ${breakpointsMedia(breakpoints.tabletWide)} {
    .role-wrapper.left {
      .left-col {
        order: 2;
      }

      .right-col {
        order: 1;
      }

      h3 {
        text-align: right;
      }

      .content-wrap {
        padding-left: 80px;
      }

      .image-wrap {
        right: -15%;
      }

      .call-out {
        right: -30px;
      }
    }

    .role-wrapper.right {
      .left-col {
        order: 1;
      }

      .right-col {
        order: 2;
      }

      h3 {
        text-align: left;
      }

      .content-wrap {
        padding-right: 80px;
      }

      .image-wrap {
        left: -15%;
      }

      .call-out {
        left: -30px;
      }
    }
  }

  .figure-title {
    color: var(--color-secondary);
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    position: relative;
    padding-left: 47px;
    margin-top: 32px;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      margin-bottom: 32px;
    }

    &:before {
      content: "";
      display: block;
      height: 2px;
      background: var(--color-secondary);
      position: absolute;
      top: 49%;
      width: 33px;
      left: 0;
    }
  }

  .facts-figures-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    @media (max-width: 992px) {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    .figure {
      height: 95px;
      width: 95px;
      background-image: url(#{$image_path}/circle-outline.png);
      line-height: 95px;
      text-align: center;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 95px;
      flex: 0 0 95px;
      position: relative;

      span {
        font-weight: bold;
        font-size: 40px;
      }

      .gradient-title {
        margin: auto;
        display: block;
      }

      .percent + .figure-value {
        margin-left: -10px;
      }

      .percent {
        position: absolute;
        right: -8px;
        top: 50%;
        z-index: 99999;
        font-size: 18px;
        width: 30px;
        display: block;
        color: var(--color-primary);
        transform: translate(0, -50%);
      }

      @media (max-width: 992px) {
        margin-bottom: 20px;
      }
    }

    .fact-wrap {
      ${breakpointsMedia(breakpoints.tabletWide)} {
        padding-left: 40px;
      }

      .fact {
        color: var(--color-secondary);
        font-size: 20px;
        line-height: 30px;
        font-weight: bold;
      }

      .source {
        font-weight: bold;
        font-size: 14px;
        line-height: 30px;
        color: #706f6f;

        a {
          font-weight: bold;
          color: var(--color-primary);
          font-size: 14px;
          line-height: 30px;
        }
      }
    }
  }

  .facts-figures-wrap {
    .slick-next,
    .slick-prev {
      @media (max-width: 680px) {
        display: none !important;
      }
    }
  }
`

export function MarketingRole({
  index,
  preTitleColor,
  preContent,
  preTitle,
  callOut,
  callOutColor,
  content,
  factsFiguresNum,
  imagePosition,
  image,
  theFactOrFigure,
  roleShortName,
  roleLink,
  source,
}) {
  const bgImage =
    image?.localFile?.childImageSharp.gatsbyImageData &&
    convertToBgImage(image.localFile.childImageSharp.gatsbyImageData)

  return (
    <>
      {(preTitle || preContent) && (
        <div className="content-wrap">
          <div className="intro">
            {preTitle && <Heading node="h2">{preTitle}</Heading>}
            {preContent && (
              <div
                className="pre-content"
                dangerouslySetInnerHTML={{ __html: preContent }}
              />
            )}
          </div>
        </div>
      )}

      <MarketingRoleStyled
        id={`role_${index}`}
        className="marketing-roles-section"
      >
        <div className={`role-wrapper ${imagePosition}`}>
          <div className="left-col">
            {roleLink ? (
              <Link to={roleLink}>
                <Heading node="h3">
                  <span dangerouslySetInnerHTML={{ __html: roleShortName }} />
                </Heading>
              </Link>
            ) : (
              <Heading node="h3">
                <span dangerouslySetInnerHTML={{ __html: roleShortName }} />
              </Heading>
            )}

            {(content || factsFiguresNum) && (
              <div className="content-wrap">
                {content && (
                  <div
                    className="content"
                    dangerouslySetInnerHTML={{ __html: content }}
                  />
                )}

                {factsFiguresNum && (
                  <>
                    <div className="figure-title">Facts &amp; Figures</div>

                    <div className="facts-figures-wrap">
                      {factsFiguresNum && (
                        <div className="figure ">
                          <span className="gradient-title">
                            {factsFiguresNum.search("%") > -1 && (
                              <span className="percent">%</span>
                            )}
                            <span className="figure-value">
                              {factsFiguresNum.replace("%", "")}
                            </span>
                          </span>
                        </div>
                      )}
                      <div className="fact-wrap">
                        {theFactOrFigure && (
                          <div
                            className="fact"
                            dangerouslySetInnerHTML={{
                              __html: theFactOrFigure,
                            }}
                          />
                        )}
                        {source && (
                          <div className="source">
                            Source:
                            <a
                              className="link pink"
                              href={source.url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {source.title}
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>

          {(bgImage || callOut) && (
            <div className="right-col">
              {image && (
                <div className="image-wrap">
                  <BackgroundImage className="image" {...bgImage}>
                    <GatsbyImage
                      image={image.localFile.childImageSharp.gatsbyImageData}
                    />
                  </BackgroundImage>
                </div>
              )}

              {callOut && (
                <div
                  className={classNames(
                    "call-out",
                    `callout-bg-color-${callOutColor}`
                  )}
                >
                  <div
                    className="inner"
                    dangerouslySetInnerHTML={{ __html: callOut }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </MarketingRoleStyled>
    </>
  )
}

MarketingRole.propTypes = {
  index: PropTypes.any,
  preTitleColor: PropTypes.any,
  preContent: PropTypes.any,
  preTitle: PropTypes.any,
  callOut: PropTypes.any,
  callOutColor: PropTypes.any,
  callOutName: PropTypes.any,
  content: PropTypes.any,
  eg: PropTypes.any,
  factsFiguresNum: PropTypes.any,
  imagePosition: PropTypes.any,
  image: PropTypes.any,
  icon: PropTypes.any,
  theFactOrFigure: PropTypes.any,
  roleShortName: PropTypes.any,
  roleShortNameColor: PropTypes.any,
  roleLongName: PropTypes.any,
  roleLink: PropTypes.any,
  source: PropTypes.any,
}
