import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Button from "@components/ui/buttons/Button"
import Heading from "@components/ui/Heading"

const ConclusionStyled = styled.section`
  background-color: #b71b54;
  padding: 100px 0 100px 0;

  .title {
    color: var(--color-text-light);
    font-size: 25px;
    line-height: 24px;
    text-align: center;
    font-weight: bold;
  }

  .content {
    color: var(--color-text-light);
    font-size: 24px;
    line-height: 34px;
    text-align: center;
  }

  .content + a {
    margin-top: 40px;
  }

  .cta-button {
    color: var(--color-primary);
    text-decoration: none;
    background-color: var(--color-bg-light);
    padding: 10px 20px;
    display: table;
    margin: 53px auto auto;
  }
`
const Conclusion = ({ title, content, link }) => {
  return (
    <ConclusionStyled>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            {title && (
              <Heading node="h2" color="white">
                {title}
              </Heading>
            )}
            {content && (
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            )}
            {link && (
              <Button color="white" to={link.to}>
                {link.title}
              </Button>
            )}
          </div>
        </div>
      </div>
    </ConclusionStyled>
  )
}

Conclusion.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  link: PropTypes.shape({
    title: PropTypes.string,
    to: PropTypes.string,
  }),
}

Conclusion.defaultProps = {
  title: `Conclusion`,
  link: {
    title: `Contact Us`,
    to: `/contact/`,
  },
}

export default Conclusion
