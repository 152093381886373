import React from "react"
import BasicBlogArticle from "../modules/BasicBlogArticle"
import styled from "styled-components"
import Heading from "@components/ui/Heading"
import Spacer from "@components/ui/Spacer"
import Prose from "@components/ui/prose"

const BlogPostsStyled = styled.section`
  @media only screen and (max-width: 700px) {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .blog-posts-container {
    padding: 60px 15px 70px 15px;
  }

  .blog-posts-feed {
    @media only screen and (max-width: 700px) {
      grid-template-columns: 1fr;
    }
    @media only screen and (min-width: 701px) and (max-width: 800px) {
      column-gap: 20px;
    }
    display: grid;
    column-gap: 50px;
    row-gap: 20px;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 45px;
  }
`

export const BlogPosts = ({ title, posts }) => {
  return (
    <BlogPostsStyled className="blog-posts">
      <div className="blog-posts-container container">
        <div className="blog-posts-inner">
          <div className="blog-posts-top">
            <Heading
              data-aos="fade-up"
              data-aos-duration="1000"
              underlined
              node="h2"
            >
              {title}
            </Heading>
          </div>
          <Spacer y="sm" />
          <div className="blog-posts-feed">
            {posts.map((post, key) => (
              <BasicBlogArticle index={key} key={post.id} {...post} />
            ))}
          </div>
        </div>
      </div>
    </BlogPostsStyled>
  )
}
